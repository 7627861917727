@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 0.2rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #21222c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #363536;
}

/* hide number increase decrease button */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dots {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  filter: url(#goo);
}

.dot {
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.dot:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #fbd301;
  position: absolute;
  left: 50%;
  transform: translateY(0) rotate(0deg);
  margin-left: -17.5px;
  margin-top: -17.5px;
}
@-webkit-keyframes dot-move {
  0% {
    transform: translateY(0);
  }
  18%,
  22% {
    transform: translateY(-70px);
  }
  40%,
  100% {
    transform: translateY(0);
  }
}
@keyframes dot-move {
  0% {
    transform: translateY(0);
  }
  18%,
  22% {
    transform: translateY(-70px);
  }
  40%,
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes dot-colors {
  0% {
    background-color: #fbd301;
  }
  25% {
    background-color: #ff3270;
  }
  50% {
    background-color: #208bf1;
  }
  75% {
    background-color: #afe102;
  }
  100% {
    background-color: #fbd301;
  }
}
@keyframes dot-colors {
  0% {
    background-color: #fbd301;
  }
  25% {
    background-color: #ff3270;
  }
  50% {
    background-color: #208bf1;
  }
  75% {
    background-color: #afe102;
  }
  100% {
    background-color: #fbd301;
  }
}
.dot:nth-child(5):before {
  z-index: 100;
  width: 45.5px;
  height: 45.5px;
  margin-left: -22.75px;
  margin-top: -22.75px;
  -webkit-animation: dot-colors 4s ease infinite;
  animation: dot-colors 4s ease infinite;
}
@-webkit-keyframes dot-rotate-1 {
  0% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes dot-rotate-1 {
  0% {
    transform: rotate(-105deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.dot:nth-child(1) {
  -webkit-animation: dot-rotate-1 4s 0s linear infinite;
  animation: dot-rotate-1 4s 0s linear infinite;
}
.dot:nth-child(1):before {
  background-color: #ff3270;
  -webkit-animation: dot-move 4s 0s ease infinite;
  animation: dot-move 4s 0s ease infinite;
}
@-webkit-keyframes dot-rotate-2 {
  0% {
    transform: rotate(165deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@keyframes dot-rotate-2 {
  0% {
    transform: rotate(165deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
.dot:nth-child(2) {
  -webkit-animation: dot-rotate-2 4s 1s linear infinite;
  animation: dot-rotate-2 4s 1s linear infinite;
}
.dot:nth-child(2):before {
  background-color: #208bf1;
  -webkit-animation: dot-move 4s 1s ease infinite;
  animation: dot-move 4s 1s ease infinite;
}
@-webkit-keyframes dot-rotate-3 {
  0% {
    transform: rotate(435deg);
  }
  100% {
    transform: rotate(810deg);
  }
}
@keyframes dot-rotate-3 {
  0% {
    transform: rotate(435deg);
  }
  100% {
    transform: rotate(810deg);
  }
}
.dot:nth-child(3) {
  -webkit-animation: dot-rotate-3 4s 2s linear infinite;
  animation: dot-rotate-3 4s 2s linear infinite;
}
.dot:nth-child(3):before {
  background-color: #afe102;
  -webkit-animation: dot-move 4s 2s ease infinite;
  animation: dot-move 4s 2s ease infinite;
}
@-webkit-keyframes dot-rotate-4 {
  0% {
    transform: rotate(705deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
@keyframes dot-rotate-4 {
  0% {
    transform: rotate(705deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}
.dot:nth-child(4) {
  -webkit-animation: dot-rotate-4 4s 3s linear infinite;
  animation: dot-rotate-4 4s 3s linear infinite;
}
.dot:nth-child(4):before {
  background-color: #fbd301;
  -webkit-animation: dot-move 4s 3s ease infinite;
  animation: dot-move 4s 3s ease infinite;
}

svg.star-svg {
  display: inline;
}
