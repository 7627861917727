.banner_item {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
}

.banner_item__img {
  overflow: hidden;
  border-radius: 10px;
}
.banner_item__img img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
  /* min-height: 370px; */
  border-radius: 10px;
}
.banner_item:hover .banner_item__img img {
  -webkit-transform: scale(1.12);
  transform: scale(1.12);
}

.call_to_action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-right: 30px;
  margin-right: 30px;
}

.call_to_action:after {
  background-color: #d8d8d8;
  position: absolute;
  content: "";
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 50px;
  width: 1px;
}

@media screen and (max-width: 490px) {
  .call_to_action:after {
    display: none;
  }
}
